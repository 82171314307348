.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.px-xs-0 {
  @media (max-width: @screen-xs-max) { 
    padding-left: 0;
    padding-right: 0;
  }
}

.my-xs-0 {
  @media (max-width: @screen-xs-max) {  
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.bdr-xs-0 {
  @media (max-width: @screen-xs-max) {  
    border-radius: 0 !important;
  }
}
.bd-xs-0 {
  @media (max-width: @screen-xs-max) {  
    border-width: 0 !important;
  }
}
.row-xs-n-16 {
  @media (max-width: @screen-xs-max) {  
    margin-right: -16px;
    margin-left: -16px;
  }
}
