@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
@import "column-border";
@import "spacers";
/*
* Vendor specific
*/
@import "vendors/parsley";
@import "vendors/slick";

html, body {
  height: 100%;
  overflow-x: hidden;
}

.mr-2{
  margin-right: 0.5rem;
}
.mr-4{
  margin-right: 1rem;
}
.p-0{
  padding:0;
}
.pl-0{
  padding-left:0;
}
.mx-auto{
  margin-right: auto;
  margin-left:auto;
}
.pt-1{
  padding-top: 15px;
}
.pb-1{
  padding-bottom: 15px;
}
.pt-2{
  padding-top: 30px;
}
.pb-2{
  padding-bottom: 30px;
}

.pt-5{
  padding-top: 50px;
}
.pb-5{
  padding-bottom: 50px;
}

.img-responsive{width: 100%;}

ul.meta{
  li{
    display: inline;
    list-style: none;
  }
}

#container {
  min-height: 100%;
  position: relative;
  margin-bottom: -222px;
  padding-bottom: 222px;
  @media (min-width: @screen-xs) {
    margin-bottom: -201px;
    padding-bottom: 201px;
  }
  @media (min-width: @screen-sm) {
    margin-bottom: -150px;
    padding-bottom: 150px;
  }
  @media (min-width: @screen-md) {
    margin-bottom: -129px;
    padding-bottom: 129px;
  }
}

.column{
  overflow:hidden;
  h1,h2,h3,h4,h5{
    margin-top:0;
  }
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}


.align-right {
  float: none;
  @media(min-width: @screen-sm){
    float: right;
  }
}

.align-left {
  float: none;
  @media(min-width: @screen-sm){
    float: left;
  }
}


.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
  @media (max-width: @screen-sm-max) {
    &-xs {
      clear: left;
    }
  }
  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    &-sm {
      clear: left;
    }
  }

  @media (min-width: @screen-md) {
    &-md {
      clear: left;
    }
  }
}

.clear-right {
  clear: right;
  @media (max-width: @screen-sm-min) {
    &-xs {
      clear: right;
    }
  } 
  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    &-sm {
      clear: right;
    }
  } 
  @media (min-width: @screen-md) {
    &-md {
      clear: right;
    }
  }
}

.lead-sm,
.lead-search {
  @media (min-width: @screen-sm) {
    font-size: @font-size-large;
  }
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  *:last-child {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
  .caption-body {
    padding: @thumbnail-caption-padding;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

  }

  &-slide {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;

  }
}


.jumbotron {
  *:last-child {
    margin-bottom: 0;
  }
}

.page-title {
  text-align: center;
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;

  &.page-title-left {
    text-align: left;
  }
}

.article-heading,
.page-header{
  &:not([class*="mt-"]) {
    margin-top: 0;
  }
  h1, h2, h3 {
    margin: 0;
    font-family: @font-family-secondary;
    color: @brand-red;
  }
}

.special-heading{
  font-family: @font-family-secondary;
  color: @brand-red;
  font-size: @font-size-h5;
}

h4,h5{
  font-family: @font-family-secondary;
  color: @brand-red;
}

.article-heading {
  margin-bottom: @line-height-computed;
}

.article {
  padding-bottom: @grid-gutter-width;
  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: center;
    margin-bottom: @font-size-h1;
  }

  h2 {
    margin-bottom: 0.5em;
  }
}

.meta-byline {
  margin-bottom: @padding-base-vertical;
}

.article-image {
  margin-bottom: 15px;

  .caption {
    .clearfix();
    padding: 9px;
  }
}

[class*="col-"].align-left {
  padding-left: 0;
  padding-right: 15px;

  @media (max-width: @screen-xs) {
    padding-right: 0;
  }
}

[class*="col-"].align-right {
  padding-right: 0;
  padding-left: 15px;
  @media (max-width: @screen-xs) {
    padding-left: 0;
  }
}

[class*="col-"].align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}


.article-image.align-left, .article-image.align-right {
  @media (min-width: @screen-sm) {
    max-width: 50%;
  }

  @media (max-width: @screen-sm) {
    padding-left: 0;
    padding-right: 0;
  }

}

.article-body {
  .clearfix();
}


// Media extends
.media {
  border: 1px solid rgba(200, 200, 200, 0.6);
  position: relative;
  
  &:not([class*="mb-"]) {
   margin-bottom: @grid-gutter-width;
  }
  
  border-radius: 0;
  @media (min-width: @screen-sm) {
    box-shadow: 0 1px 1px rgba(0,0,0,.05);

    &:hover {
      border-color: rgba(200, 200, 200, 0.4);
      box-shadow: 0 1px 15px rgba(0,0,0,.05);
    }
  }


  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    
    .media-right, .media-left {
      padding: 15px;

      @media (max-width: @screen-sm) {
        .media-object {
          max-width: 100%;
          margin-left: auto;
          margin: auto;
          width:100%;
        }

        &[class*="col-"] {
          padding: 0;
        }
      }
    }

    .media-right {
      padding: 0 15px;
    }

    .media-right, .media-left {
      
      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
     
        &[class*="col-"] {
          padding: 0;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }
    }

    .media-body {
      padding: @panel-body-padding;
    }
  }

  &-galleries {
    padding: 4px;
    background-color: #fff;
    .media-summary {
      padding: @padding-small-vertical;
    }
  }

  &-default{
    position: relative;
    .media-body{
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position:absolute;
      .panel-icon{
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .media-heading{
      color:#fff;
      max-width: 80%;
      margin-left: auto;
      margin-right:auto;
      font-size: 30px;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .overlay{
      height: 100%;
      background: rgba(0,0,0, 0.5);
    }
    &:hover .overlay{
      height: 100%;
      background: rgba(250, 94, 33, 0.7);
    }
  }

  &-ctablock{
    overflow: visible;
    position:relative;
    border: none;
    border-radius:0;
    .media-body{
      position:relative;
      background: @brand-secondary;
      padding: 10px;
      left: auto;
      top: auto;
      @media(min-width: @screen-sm){
        position:absolute;
        width: 40%;
        right: -50px;
        bottom: -50px;
        padding: 30px;
      }
    }
    .post-cats{
      color:@brand-rust;
    }
    .media-heading{
      color:#fff;
      font-size: 30px;
      max-width: 100%;
    }
    .media-summary .icons{
      color:@brand-red;
    }
  }
}

.media.media-responsive.media-resources{
  &.basic .media-body{
    background: transparent;
  }
  .media-left{
    padding:0;
     @media (max-width: @screen-sm) {
      display: block;
      float: none !important;
      img{
        width: 100%;
      }
    }
  }
  .media-body{
    background: @brand-secondary;
  }
  .post-cats{
      color:@brand-rust;
    }
    .media-heading{
      color:#fff;
      font-size: 30px;
      max-width: 100%;
    }
    .media-summary .icons{
      color:@brand-red;
    }
    .downloads{
      .file,.action-list{
        float: left;
        text-align: left;
        clear: both;
        width: 100%;
        @media(min-width: @screen-xs){ 
          clear: none;
        }
      }
      .action-list{
        @media(min-width: @screen-xs){
          width: 40%;
          text-align: right;
        }
        @media(min-width: @screen-md){
          width: 35%;
        }
      }  
      .file{
        @media(min-width: @screen-xs){
          width: 60%;
        }
         @media(min-width: @screen-md){
          width: 65%;
        }
      }
      &.basic{
        @media(min-width: @screen-xs){ 
          clear: both;
        }
        .file,.action-list{
          width:100%;
          text-align: left;
        }
      }  
    }  
}

.media-cart {
  .media-right, .media-left, .media-body {
    padding: @panel-body-padding;
  }
  .media-left + .media-body {
    padding-left: 0;
  }


  .media-object {
    width: 80px;
  }

  @media (min-width: @screen-xs) {
    .media-object {
      width: 120px;
    }
  }
  @media (min-width: @screen-sm) {
    .media-object {
      width: 160px;
    }
  }
}

.media-product-min {
  .details {
    display: none !important;
  }
}

.media-stacked {

  .media-left {
    .media-object {
      max-width: 100%;
      margin-left: auto;
      margin: auto;
    }
  }
  .media-right,
  .media-left {
      display: block;
      float: none;
  }
  .media-right {
    padding: 0 !important;
  }
  .form {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}


.media-stacked-xs {
  @media (max-width: @screen-xs-max) {
    .media-stacked();
  }

  @media (min-width: @screen-xs) {
    .media-object {
      width: 200px;
    }
  }

  @media (min-width: @screen-sm) {
    .media-object {
      width: 240px;
    }
  }
}
.media-product-min-xs {
  @media (max-width: @screen-xs-max) {
    .media-product-min();
  }
  @media (min-width: @screen-sm) {
    .btn-submit-icon {
      display: none;
    }
  }
}

.btn.media-link {
  padding-left: 30px;
  padding-right: 30px;
  font-size: @font-size-small;
}


.btn-outline-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
  background-color: transparent;
}

.media-footer-collapse {
  border-top: 1px solid rgb(200, 200, 200);
  padding: @panel-body-padding;
  *:last-child {
    margin-bottom: 0;
  }

  .panel {
    border-width: 0;
    box-shadow: none;
    border-radius: 0;
  }

  @media (min-width: @screen-sm-min) {
    padding-bottom: 0;
    .panel-group {
      margin-right: (-1 * @panel-body-padding); 
      margin-left: (-1 * @panel-body-padding); 
    }

    .panel + .panel {
      margin-top: 0; 
    }
  }

  .panel-body {
    background-color: rgb(51, 51, 51);
    color: rgb(245, 245, 245);
  }

  
}

.panel-body *:last-child {
  margin-bottom: 0;
}

.meta {
  color: rgb(102, 102, 102);
  a { 
    color: inherit;
    &:after {
      color: @link-color;
    }
  }

  &:not([class*='mb']) {
    margin-bottom: (@line-height-computed / 2);
  }
}

.media .meta {
  @media (max-width: 575px) {
    font-size: 11px;
  } 
}

.media-heading {
  @media (max-width: 575px) {
    font-size: 18px;
  }
  // &:hover {
  //   color: @link-color;
  // }
}

.media-heading a {
  color: inherit;
  &, &:hover, &:focus {
    text-decoration: none;
    color: @link-color;
  }
}

.media-product {
  position: relative;
  @media (max-width: @screen-sm-max) {

    .media-right {
      display: block;
      position: relative;
    }

    .form {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}


.media-footer-heading {
  margin-bottom: @panel-body-padding;
}

.nav-stacked {
  .nav-stacked {
    > li > a {
      padding-left: 40px;
    }
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: #fff;
  }
}

.breadcrumb {
  margin-bottom: 0;
  padding: 15px;
  .breadcrumb-item a{
    color:@brand-red;
  }
}

.breadcrumb>li+li:before {
  content: " - ";
  font-size: 20px;
  .fa-icon();
}


.embed-responsive-3by1 {
  padding-bottom: percentage(1/3);
}
.map-responsive {
  .oh-google-map-canvas {
    .embed-responsive;
    height: 0 !important;
    width: 100% !important;
  }
}
.map-responsive-3by1 .oh-google-map-canvas {
  padding-bottom: percentage(1/3);
}

.map-responsive-4by3 .oh-google-map-canvas {
  padding-bottom: percentage(3/4);
}


#footer {
  width: 100%;
  background: rgb(51, 51, 51);
  color: #fff;
  padding: 20px 0;
  @media (max-width: @grid-float-breakpoint) {
    padding-bottom: 60px;
  }
  .nav{
    flex-wrap: wrap;
    > li{
      width:100%;
      .flex-fill();
      &.nav-item-level-1 a{
        font-size: 20px;
      }
      a{
        color:#fff;
        padding-left:0;
        font-family: @font-family-secondary;
        &:hover{
          color:@brand-primary;
        }
      }
      @media(min-width: @screen-sm){
        width:auto;
      }
    }
  }  
  .menu{
    list-style: none;
    padding-left:0;
    li.-item-level-2 a{
      color:lighten(@gray-base, 45%);
      font-size: 16px;
      &:hover{
        color:@brand-primary;
        text-decoration: none;
      }
    }
  }
}

.site-footer {
  font-size: @font-size-small;
  color: #bfbfbf;
  
  a {
    color: inherit;
    text-decoration: underline;
    line-height: @line-height-small;
  }
}

.list-social {
  &-header{
    a{
      @media (min-width: @screen-md) {
        color:#fff;
      }
      &:hover{
        color:@brand-primary;
      }
    }
  }
  &-footer {
    
    padding: (@padding-base-vertical + 1) 0;
    margin-bottom: @grid-gutter-width;
    a {

      color: #bfbfbf;
    }
    path {
       fill: #bfbfbf;
    }
  }
}


.fa-blogger svg{
  width: 14px;
  height: auto;
}

// .main-menu {
//   @media (min-width: @grid-float-breakpoint) {
//     display: none;
//   }
// }
@media (max-width: @grid-float-breakpoint) {
  .main-menu {
    background-color: #fff;
    position: fixed;
    width: 100%;
    left: -100%;
    z-index: 1000;
    top: 50px;
    bottom: 0;
    overflow-y: scroll;
    transition: all .25s ease-out;

    .panel-heading-flush,
  .list-group-flush .list-group-item {
    padding-left: 30px;
    padding-right: 30px;
  }

    &.in {
      left: 0;
      padding-top: 10px;
    }

    .panel-nav {
      margin-bottom: 0;
    }
  }
}

.navbar-collapse-main {
  @media (min-width: @grid-float-breakpoint) {
    border-width: 0;
  }
}

.navbar-nav-primary {
  font-size: 21px;
  @media (min-width: @screen-sm) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
   @media (min-width: @screen-lg) {
    font-size: 23px;
   }
  > li > a {
      font-family: @font-family-secondary;
      @media (min-width: @screen-md) {
        padding:13px 9px;
        line-height: 35px;
      }
      @media (min-width: @screen-lg) {
        padding:13px 13px;
      }
    }

  > li.nav-item-search {
      @media (max-width: @grid-float-breakpoint) {
        position: absolute;
        top: 0;
        right: 30px;
        left: 100%;
        transition: all .25s ease-out;
        overflow: hidden;

        &.in {
          left: 0
        }
      }
    }
}

.navbar-right .dropdown-menu{
  right: auto;
}

// .nav-item-level-1.active > .nav-item-link:after {
//   border-bottom-color: @brand-red;
// }

// .nav-item-level-1 > .nav-item-link {
//   position: relative;
//   &:after {
//     @media (max-width: @grid-float-breakpoint) {
//       content: '';
//     }
//     position: absolute;
//     border-bottom: 3px solid transparent;
//     width: ~'calc(100% - 30px)';
//     bottom: -2px;
//     left: 15px;
//   }
// }

#bar {
  // background-color: rgb(247, 248, 247);
  padding: 5px 0;
  font-size: 18px;
  font-family: @font-family-secondary;
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
  @media (min-width: @grid-float-breakpoint) {
    position: relative;
    padding-bottom: 23px;
  }
  
}

.bar-nav > .bar-nav-item > span {
  display: block;
}

.bar-nav > .bar-nav-item > .bar-nav-link {
  color: @text-color;
  padding: 5px 10px 0 0; 
  font-size: 16px;
  &:hover{
    color:@brand-primary;
  }
  @media(min-width:@screen-sm){
    padding: 11px @padding-base-horizontal; 
    font-size: 18px;
  }
}

.bar-nav-footer {
  @media (min-width: @screen-sm) {
    text-align: right;
    margin-bottom: 0;
  }
  .bar-nav-link {
    color: #bfbfbf; 
  }
  .bar-nav-item path { fill: @link-color;}
  .bar-nav-item a:hover path { fill: @link-hover-color;}
}

.img-logo {
  height: 24px;
  width: 100%;

  @media (min-width: @screen-lg) {
    width: auto;
  }
  > svg {
    height: inherit;
    width: 100%;
    @media (min-width: @screen-lg) {
      width: auto;
    }
  }
  @media (min-width: @screen-xs) {
    height: 26px;
  }
}

.navbar-logo {

  display: block;
  float: none;
  text-align: center;

  .img-logo {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: @grid-float-breakpoint) {
    height: auto;    
    background-color: rgb(175, 169, 166);
    margin-top: -9px;
    margin-bottom: -18px;
    margin-left: 0;
    padding: 25px 30px 20px;
  }
  @media(min-width:@screen-md){
    width: 260px;
  }
  @media(min-width:@screen-lg){
  width: auto;
  }
}

.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
  @media (min-width: @grid-float-breakpoint) {
    margin-left: 0;
  }
}

.site-header{
  background-color: #afa9a6;
  @media (min-width: @screen-md) {
    background-color: #fff;
  }
}

.site-header .navbar-default {
  @media (min-width: @grid-float-breakpoint) {
    margin-bottom: @grid-gutter-width;
  }
  @media (max-width: @grid-float-breakpoint) {
    // background-color: rgb(175, 169, 166);
    // border-color: rgb(175, 169, 166);
  }
}


.btn-link.navbar-toggle {
  padding-top: 4px;
  padding-bottom: 4px;
}

.navbar-toggle {
  position: absolute;
  top: 0;
  margin-right: 0;
  &.right {
    right: 5px;
  }
  &.left {
    left: 5px;
  }

}



// Panel
.panel-nav {
  border-width: 1px 0;
  box-shadow: none;
  border-radius: 0;
  .panel-heading {
    background-color: #fff;
  }
  .panel-title {
    font-size: 24px;
    font-family: @font-family-secondary;
  }
}

.panel-heading-flush,
.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.tab-pane .list-group-item {
  border-width: 1px 0;
}

.panel-relatedpanel.slideshow {
  @media (max-width: @screen-sm-max) {  
    margin-left: -15px;
    margin-right: -15px;
  }
}

// collapse
.collapse-icon {
  position: relative;
  padding-right: 24px;
  &:after {
    .fa-icon();
    content: @fa-var-angle-down;
    position: absolute;
    top: ~"calc(50% - 7px)";
    right: 10px;
    transition: all 0.2s ease;
  }
}

[data-toggle="offcanvas"].btn {
  &:not(.slide-in) {
    .offcanvas-open {
      display: none;
    }
  }

  &.slide-in {
    .offcanvas-close {
      display: none;
    }
  }
}

[data-toggle="collapse"] {
  &:not(.navbar-toggle):not(.btn) {
    .collapse-icon();
  }

  &:not(.collapsed) {
    &:after {
      transform: rotate(-90deg);
    }
  }

  &.panel-title {
    display: block;
    padding-right: 0;
    &:after {
      right: 0;
    }
  }

  &:focus, &:active, &:hover {
    text-decoration: none;
  }
}

.btn-lg{
  min-width: 200px;
  border-radius: 4px;
  padding: 15px;
}
.btn-md{
  min-width: 150px;
  border-radius: 4px;
  padding: 10px;
}

.media-footer {
  .btn-group .btn-default:not(.collapsed), 
  .btn-group .btn-default:focus {
    background-color: @btn-primary-bg;
    border-color: @btn-primary-bg;
    color: @btn-primary-color;
    font-weight: 300;
  }

  .btn-link {
    color: @btn-primary-bg;
  }
}

// Nav bar dropdown
.navbar-nav{
  margin:0;
}
.navbar-default .navbar-nav{
  @media(max-width: @screen-md-min){
    li a{
      color:@text-color;
    }
    li a:hover,  .open a,  .open a:focus,  .open a:focus, li.active a, li.active a:hover{
      color:@brand-red;
    }
  }
}
.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
      &:after {
        .fa-icon();
        content: @fa-var-chevron-down;
        position: absolute;
        top: ~"calc(50% - 6px)";
        right: 10px;
        font-size: 0.5em;
      }
    }
}

.page-heading{
  h3{
    font-family: @font-family-sans-serif;
    color:@gray-light;
    @media(max-width:@screen-sm){
     margin-top: 12px;
    }
  }
  h1{
    color:#fff;
    font-family: @font-family-secondary;
    font-size: 50px;
    margin: 0;
    @media(min-width:@screen-md){
      font-size: 72px;
    }
  }
}

.navbar-default .navbar-nav .dropdown-menu{
  padding: 0;
  @media(min-width:@screen-md){
    &:after {
      bottom: 100%;
      left: 8%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 15px;
      margin-left: -15px;
    }
    &:before {
      bottom: 100%;
      left: 8%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(0, 0, 0, .3);
      border-width: 16px;
      margin-left: -16px;
    }
  }  
  .menu{
    list-style: none;
    padding:5px 0;
    li a, .open a{
      padding: 3px 30px;
      white-space: nowrap;
      display: block;
      color:@text-color;
      &:hover{
        color: @brand-red;
        text-decoration: none;
      }
    }
    @media(min-width: @screen-md){
      padding:20px 0;
    }
  }
   @media(max-width: @screen-md-min){
    .navimage{
      display: none;
    }
  }
}

// Nav form

.navbar-form {
  
  .form-control {
    box-shadow: none;
    width: 100%;
  }


  @media (max-width: @grid-float-breakpoint ) {
    border: 0 solid;
    box-shadow: none;
    margin-top: 0;
    margin: 0;
    padding: 6px 15px;
  }
}

// share
.article-sharing {
  min-height: 22px;
  margin-left: -5px;
}

.share-btn {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  color: @text-color;
  font-size: 14px;
}

// List group extends
.list-group-item-meta {
  font-size: @font-size-small;
}


// pagination 

.pagination-center {
  display: flex;
  justify-content: center;
}
/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;
  }
  
  .notification[class*="notice"] {
    .alert-info;
  }
  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {

    border-radius: 0;

    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification + .notification {
    border-top: 0;
  }
}

// Dropdown sub-menu

.dropdown-submenu {
  position: relative;
}

.navimage{
    width: 320px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-color: @gray-lighter;
    background-position: center;
    padding-bottom: 45%;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  .fa-icon();
  content: @fa-var-chevron-right;
  position: relative;
  top: ~"calc(50% - 6px)";
  right: -9px;
  font-size: 0.75em;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer, .grid-item {
  width: 33%;
  margin-bottom: 5px;

}

.gallery-image {
  padding: 5px;
  width: 33%;
  float: left;
  position: relative;
}

.gallery-image-link {
  display: block;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  .img-responsive {
    position: absolute;
    .square(100%);
  }
  .caption {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    padding: 5px;
    color: #fff;
  }
}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }
  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  &:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover, a.thumbnail:active {
  text-decoration: none;
}

.pagination-sm>li:first-child>a, 
.pagination-sm>li:first-child>span,
.pagination-sm>li:last-child>a, 
.pagination-sm>li:last-child>span { border-radius: 0; }


// Form extends

// .input-icon {

//   position: relative;
//   box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0px 1px rgba(0, 0, 0, 0.07);
//   border:1px solid @input-border;
//   border-radius: @input-border-radius;

//   .form-control {
//     border: 0;
//     box-shadow: none;
//   }
// }

// .input-icon-item {
//   position: absolute;
//   right: 0;
//   top: 0;
//   z-index: -1;
// }
[for="fields-recaptcha"] {
  display: none;
}
.select select {
  display: block;
  position: relative;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}



.form-qty {
  width: 70px;
}

.form-remove-item {
  position: absolute;
  right: 0;
  top: 0;
  .btn {
    border-color: transparent;
    &:hover, &:focus, &:active {
      background-color: #fff;
    }
  }
}

.field.form-group {
  position: relative;
}

.sprout-email-button {
  padding: 0 !important;
  right: 6px !important;
  bottom: 11px !important;
  margin: 0 !important;
  line-height: 1 !important;
}

.field .instructions {
  color: lighten(@text-color, 25%);
  font-size: @font-size-small;
  margin-bottom: 5px;
  *:last-child {
    margin-bottom: 0;
  }
}

.control-label, label {
  + .instructions {
      margin-top: -5px;
  }
}

label.required:after {
  content: ' *';
  color: @brand-danger;
}


legend {
  font-size: @font-size-base;
  text-transform: uppercase;
}

.form-control-3 {
  width: percentage(3/12);
}

.list-flex .list-flex-item {
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-top-color: @gray-light;
  }
}


.flex-row {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;

  .col-sm {
    position: relative;
    width: 100%;
    padding: 0 10px;
  }

  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
  }
}

.panel-icon {
  display: inline-block;
  background-color: @brand-rust;
  color: #fff;
  padding: 15px;
  min-width: 54px;
  text-align: center;
  &.exhibitions{
    font-size: 25px;
    padding: 8px;
    font-family: @font-family-secondary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


.input-icon {
  position: relative;
  &:after,
  &:before {
    .fa-icon();
    position: absolute;
    z-index:1;
    top: ~'calc(50% - 8px)';
    color: @gray;
  }

  &.left { 
    &:before {
      left: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control {
      padding-left: 2.2em;
    }
  }

  &.right { 
    &:after {
      right: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control {
      padding-right: 2.2em;
    }
  }


    > .dropdown,
    > .form-control {
      &:focus, &:active {
        outline: none;
      }
    }

    &.select {
      input[type="text"] {
        border: 1px solid @gray-dark;
        margin-top: 5px;
      }
    }


  &.center:before {
    left: ~"calc(50% - 7px)";
  }
}

.input-icon.search.left:before { content: @fa-var-search; }
.input-icon.search.right:after { content: @fa-var-search; }
.input-icon.select.left:before { content: @fa-var-angle-down; }
.input-icon.select.right:after { content: @fa-var-angle-down; }

.dropdown-cavet {
  padding-right: 30px;
  &:after {
    .fa-icon();
    content: @fa-var-chevron-down;
    position: absolute;
    top: ~"calc(50% - 6px)";
    right: 9px;
    font-size: 0.75em;
  }
}


.field .dropdown {
  + .form-control {
    margin-top: 6px;
  }
  &.open .btn {
    border-color: @input-border-focus;
  }
  .btn {
    background-color: transparent;
    padding-right: 0;
    width: 100%;
    text-align: left;
    .dropdown-cavet();
    .text-overflow();
    border-color: @input-border;

    &:active, &:focus {
      outline: none;
      box-shadow: none;
      border-color: @input-border-focus;
    }
  }

  .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
    > li {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.5;

      small {
        display: none;
      }

      &:not(.disabled) {
        cursor: pointer;
        &:hover {
          background-color: @gray-lighter;
        }
      }
      &.disabled {
        color: #777;
        cursor: not-allowed;
      }
    }
  }
}

.has-error .dropdown {
  border-color: @brand-danger;
}

a.forgot-password {
  float: right;
  position: relative;
  display: block;
  z-index: 1;
}

.form-control-xs-1 { 
  width: percentage(1/12) !important;
}

.form-group-sm .select select.form-control {
  line-height: @line-height-small;
}

.form-group-nested {
  .row {
    .make-row(15px);
  }

  [class*='col-'] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .control-label-sm,
  .control-label-sm label {
    font-size: @font-size-small;
  }


  
}

// Grid

.container-sm {
  
  @media (min-width: @screen-sm) {
    width: @container-sm;
  }

  > .container {
    max-width: 100%;
  }
}

// Container-max-widths
.container-full {
  max-width: 100% ;
}
.container-md {
  @media (min-width: @screen-md) {
    max-width: 960px;
  }
}
.container-sm {
   @media (min-width: @screen-sm) {
    max-width: 720px;
  }
}


/* Steps
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.steps { margin-bottom: 40px; }
.steps ul { display: flex; display: -webkit-flex; margin-bottom: 0;}
.steps ul li { list-style: none; display: block; flex:1; }
.steps ul li { color: #aaa; }
.steps ul li a { color: #aaa; }
.steps ul li.done { color: #DA5A17; opacity: .5; }
.steps ul li.done a { color: #DA5A17; }
.steps ul li.sel { color: #DA5A17; opacity: 1; }
.steps ul li.sel a { color: #DA5A17; }
.steps .meter { height: 10px; position: relative; border: 1px solid #bbb; -moz-border-radius: 10px; -webkit-border-radius: 10px; border-radius: 10px; padding: 3px; margin-bottom: 10px; }
.steps .meter > span { display: block; height: 100%; border-top-right-radius: 2px; border-bottom-right-radius: 2px; border-top-left-radius: 10px; border-bottom-left-radius: 10px; background-color: #DA5A17; position: relative; overflow: hidden; }

@media (max-width: 750px) {
    .steps ul { display: block; margin-left: 24px; }
    .steps ul li { list-style: decimal; display: list-item; }
    .steps .meter { display: none; }
}

/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: 767px) {
  .navbar-nav>li>a{
    padding-top:5px;
    padding-bottom:5px;
  }

  .row-offcanvas {
    position: relative;
    transition: all .25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-right .sidebar-offcanvas {
    right: -75%; 
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -75%; 
  }

  .offcanvas {
    overflow: hidden;
  }

  .offcanvas.in {
    .row-offcanvas-right {
      right: 75%;
      margin-right: 0;
    }

    .row-offcanvas-left {
      left: 75%;
      margin-left: 0;
    }
  }
  
  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 75%;

    
  }
}
// Column modifiers
@media (min-width: @screen-xs) and (max-width: @screen-sm) {
  .col-xs-sm-4 {
    width: percentage(4/12);
    float: left;
  }

  .col-xs-sm-6 {
    width: percentage(6/12);
    float: left;
  }
  
}


.bdrs-0 {
  border-radius: 0 !important;
}
.va-b {
  vertical-align: bottom;
}

.hr-sm {
  margin-top: @line-height-computed / 2;
  margin-bottom: @line-height-computed / 2;
}
.bds-ds {
  border-style: dashed;
}

.form-flex {

  margin-left: -5px;
  margin-right: -5px;
  @media (min-width: @screen-sm) {
    display: flex;
  }
  
  .form-group {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
    float: left;
    @media (min-width: @screen-sm) {
      margin-bottom: 0;
      flex: 1 1 auto;
    }
  }
}

.ai-fe {
  align-self: flex-end;
}

.py-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.w-100 {
  width: 100%;  
}

.w-50 {
  width: 50%
}

.z-index1{
  z-index: 1;
}
.overlay{
  position:absolute;
  width: 100%;
  top: 0;
  height:35%;
  background: rgba(0, 0, 0, 0.40);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,5) 35%, rgba(0,0,0,0) 100%);
}
.d-flex{
  display: flex;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-between{
  justify-content: space-between;
}
.flex-fill{
  flex: 1 1 auto!important;
}
.align-items-center{
  align-items: center;
}
.align-self-top{
  align-items: top;
}
.pos-a{
  position:absolute;
  z-index: 1;
}
.border-0{
  border:none;
}
.shadow-0{
  box-shadow: none;
  &:hover{
    box-shadow: none;
  }
}
.banner{
  position:relative;
}
.infoblock .list-group {
  .list-group-item{
    background-color:@brand-rust;
    color:#fff;
    padding: 25px 15px;
    font-size: 18px;
    font-weight: 300;
    border-left: none;
    border-right: none;
      .fa{
        margin: 5px 15px 20px 0;
        font-size: 25px;
        float: left;
      }
      &:first-child,&:last-child{
        border-radius:0;
      }
      .list-group-item-text{
        line-height: 2;
      }
      &.tickets{
        background-color:#000;
        text-align: center;
        border: none;
        padding: 15px;
        .fa{
          float: none;
          color:@brand-red;
          font-weight: bold;
          margin: 0 0 0 10px;
          font-size: 25px;
        }
      }
  }
}
.bg-white{
  background-color:@white;
}
.bg-rust{
  background-color:@brand-rust;
}
.bg-light{
  background-color:@gray-lighter;
}
.bg-dark{
  background-color:@gray-dark;
}
.bg-primary{
  background-color:@brand-primary;
}
.carousel-inner{
  .media-ctablock{
    .media-heading a{
      color:@white;
    }
    @media(min-width: @screen-sm){
      margin-bottom: 50px;
      margin-right: 50px;
    }
  }
  .media-cta{
    padding: 0 2%;
    // @media(min-width:@screen-md){
    //   padding: 0 5%;
    // }
  }
  .slick-dots{
      li{
        display: inline-block;

        &.slick-active button:before{
          background-color: @brand-orange;
          color:@brand-red;
        }
        button:before{
          border-radius: 100%;
          border:2px solid @brand-orange;
          content:"";
          width: 13px;
          height: 13px;
          opacity: 1;
        }
      }
      @media(min-width: @screen-sm){
        width: auto;
        bottom: 25%;
        left: 35px;
        li{
          display: list-item;
        }
      }
  }
  &.dark{
    .slick-prev:before,.slick-next:before{
      color:@brand-primary;
    }
  }
}
.bg-sectionImage{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    color: #fff;
}
.pageBgImage{
  position:absolute;
  width: 100%;
  bottom:5%;
  z-index: -1;   
}     
.videoplaybtn{
  color: #fff;
  font-size: 30px;
  border: 1px solid #fff;
  border-radius:100%;
  width: 50px;
  height: 50px;
  text-align: center;
  .fa{
    margin-left: 5px;
  }
}
[data-magnific-video]:hover .videoplaybtn{
    color:@brand-orange;
    border: 1px solid @brand-orange;
}
.list-unstyled li{
  margin-bottom: 5px;
}
.nav-pagelist {
  text-align: center;
  li{
    display: inline-block;
    vertical-align: middle;
    color:lighten(@gray-base, 45%);
    a{
      color:@brand-orange;
      &:hover{
        color:darken(@brand-orange, 20%);
      }
    }
    a .arrow{
      padding: 0 15px;
    }
  }
}
// .listing-pagination{
//   background-size: cover;
//   background-repeat: no-repeat;
//   padding: 0 40px 40px;
// }

.matrix-quickfacts li{
    list-style: none;
    padding-left: 30px;
    position: relative;

    .fa{
      font-size: 22px;
      position: absolute;
      left: 0;
      top: 0;
      color:@brand-red;
    }
}
.bg-img{
  height: 100vh;
  width:100%;
  background-size:cover;
}
.shop-btn{
  margin-top:6px;
  margin-bottom:6px;
}
.shop-btn .bar-nav-item .bar-nav-link{
  padding-top:5px;
  padding-bottom:5px;
  background-color: @brand-red;
  border-radius:0;
  color: @white;
  &:hover{
    background-color: darken(@brand-red, 10%);
  }
  @media(max-width:@screen-md){
    width:100px;
    text-align: center;
  }
}

.text-white{
  color:#fff;
}

.text-decoration-none {
  text-decoration: none !important;
}

.hours{
  margin-top: 10px;
}

.tickets{
  background-color:#000;
  text-align: center;
  border: none;
  .fa{
    float: none;
    color:@brand-red;
    font-weight: bold;
    margin: 0 0 0 10px;
    font-size: 20px;
  }
  &:hover{
    color:@brand-red;
  }
}
#fields-recaptcha-field{
  margin-bottom:0;
}
#subscribers {
  .form-group{
    @media(min-width:@screen-sm){
      padding-right: 10px;
      float: left;
    }
  }  
  #submit-7154{
    @media(min-width:@screen-sm){
      margin-top:29px;
    }
  }
}

.page-mid{
  padding: 10px 15px;
}

.d-block{
  display:block;
}

.d-sm-block{
  @media(max-width:@screen-sm){
    display:block;
  }
}

.grecaptcha-badge{
  visibility: hidden;
}

.text-light{
  color:@white;
}

[data-slick-carousel-default]{
  margin-bottom: 0;
}